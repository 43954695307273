import * as Parse from 'parse';
import { ParseQueryParams } from '../interface/query.interface';

export enum ParseUserType {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  super_admin = 'super_admin',
  customer    = 'customer',
  vendor      = 'vendor',
  anonymous   = 'anonymous',
}

export interface ParseUserQueryParams extends ParseQueryParams {
  type?: string;
  canonical?: string;
  orderBy?: string;
  orderByField?: string;
}

export class ParseUser extends Parse.User {
  get description(): string {

    if (!this.name) {
      return;
    }

    let description = this.name;

    if (this.email) {
      description += ' (' + this.email + ')';
    } else if (this.authData?.facebook) {
      description += ' (Facebook)';
    } else if (this.authData?.google) {
      description += ' (Google)';
    } else if (this.authData?.apple) {
      description += ' (Apple)';
    } else {
      description += ' (' + this.username + ')';
    }

    return description;
  }

  get name(): string {
    return this.get('name');
  }

  set name(val: string) {
    this.set('name', val);
  }

  get firstName(): string {
    return this.get('firstName');
  }

  get lastName(): string {
    return this.get('lastName');
  }

  get username(): string {
    return this.getUsername();
  }

  set username(val: string) {
    this.setUsername(val);
  }

  get email(): string {
    return this.getEmail();
  }

  set email(val: string) {
    this.setEmail(val);
  }

  get phone(): string {
    return this.get('phone');
  }

  set phone(val: string) {
    this.set('phone', val);
  }

  get password(): string {
    return this.get('password');
  }

  set password(val: string) {
    this.setPassword(val);
  }

  get photo(): Parse.File {
    return this.get('photo');
  }

  set photo(val: Parse.File) {
    this.set('photo', val);
  }

  get permissions(): string[] {
    return this.get('permissions') || [];
  }

  set permissions(val: string[]) {
    this.set('permissions', val);
  }

  get equipmentCategory(): string[] {
    return this.get('equipmentCategory') || [];
  }

  set equipmentCategory(val: string[]) {
    this.set('equipmentCategory', val);
  }

  get features(): string[] {
    return this.get('features') || [];
  }

  set features(val: string[]) {
    this.set('features', val);
  }
  get dietary(): string[] {
    return this.get('dietary') || [];
  }

  set dietary(val: string[]) {
    this.set('dietary', val);
  }

  get type(): string {
    return this.get('type');
  }

  set type(val: string) {
    this.set('type', val);
  }

  get authData(): any {
    return this.get('authData');
  }

  get invoicee(): string {
    return this.get('invoicee');
  }

  set invoicee(val: string) {
    this.set('invoicee', val);
  }

  get invoiceeAddress(): string {
    return this.get('invoiceeAddress');
  }

  set invoiceeAddress(val: string) {
    this.set('invoiceeAddress', val);
  }

  isAnonymous(): boolean {
    return this.authData && this.authData['anonymous'];
  }

  isCustomer(): boolean {
    return this.type === ParseUserType.customer;
  }

  isAdmin(): boolean {
    // Mongo does not have an 'admin' type
    return this.isSuperAdmin();
  }

  isSuperAdmin(): boolean {
    return this.type === ParseUserType.super_admin;
  }

  isVendor(): boolean {
    return this.type === ParseUserType.vendor;
  }

  hasPermissions(): boolean {
    return this.permissions && this.permissions.length > 0;
  }
}

Parse.Object.registerSubclass('_User', ParseUser);
